<template>
  <div class="chain-list" :class="{ isResult }">
    <div class="listsearchWrape" :class="{ isDark: isDark }" v-if="isFirstPage">
      <div
        v-if="isMask"
        class="inputSubWrapple"
      >
        <div class="searchIcon" :class="{ isDark: isDark }"></div>
        <input
          v-model="searchQuery"
          :placeholder="$t('search_placeholder')"
          @input="onInput"
          @focus="onSearchFocus(true)"
          @blur="onSearchFocus(false)"
          class="border-none-transparent input-indent"
          :class="{ dark: isDark }"
          :style="{ color: isDark ? '#fff' : '' }"
        />
      </div>
    </div>
    <div v-if="!chainList.length" class="empty-view">
      <div class="empty-in" />
      <div class="empty-text">{{ $t("chain_not_found") }}</div>
    </div>
    <div class="chain-list-wrap" v-if="chainList.length">
      <div
        class="chain-item d-flex ai-center jc-between"
        v-for="(chain, index) in chainList"
        :key="index"
        @click.stop="
          chooseChain(index, chain.blockchain_id, chain.chain_id, chain.ns)
        "
      >
        <div class="left d-flex ai-center">
          <img :src="chain.icon" :class="{ isResult }" />
          <span class="text" :class="{ isResult }">{{ chain.label }}</span>
        </div>
        <img class="right" src="../assets/index/arrow-right.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    chainList: {
      type: Array,
    },
    isResult: {
      type: Boolean,
    },
    isMask: {
      type: Boolean,
    },
    isFirstPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchQuery: ''
    }
  },
  computed: { ...mapGetters(['isDark']) },
  methods: {
    onInput() {
      this.$emit('onSearchFocus', this.searchQuery, false)
    },
    onSearchFocus(bool) {
      if (bool) {
        this.searchQuery = "";
      }
      // this.activeSelectedOrSearch = bool;
      this.$emit('onSearchFocus', this.searchQuery, bool)
    },
    chooseChain(index, blockchain_id, chain_id, ns) {
      this.$emit("chooseChain", index, blockchain_id, chain_id, ns);
    },
  },
};
</script>

<style lang="scss" scoped>
.chain-list {
  position: absolute;
  z-index: 100;
  width: 4.4rem;
  max-height: 3rem;
  overflow: auto;
  top: 0.58rem;
  left: -0.01rem;
  padding: 0.1rem;
  background: var(--chain-list-bg);
  border: 0.01rem solid var(--chain-list-border);
  border-radius: 0.12rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .empty-view {
    width: 100%;
    min-height: 3rem;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-in {
      width: 0.48rem;
      height: 0.48rem;
      background: url(../assets/no-result.png);
      background-size: cover;
    }
    .empty-text {
      margin-top: 0.1rem;
      color: #777;
      font-size: 0.14rem;
    }
  }
  &.isResult {
    width: 2.98rem;
    max-height: 3.64rem;
    top: 0.5rem;
  }
  .listsearchWrape {
    background-color: var(--chain-input-bg);
    border-radius: 0.16rem;
    border: 0.01rem solid #e9e9e9;
    .inputSubWrapple {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 0.16rem;
      input::placeholder {
        color: #bbb;
      }
      .searchIcon {
        width: 0.24rem;
        height: 0.24rem;
        background: url("../assets/search_light@2x.png") no-repeat;
        background-size: cover;
      }
      input {
        margin-left: 0.08rem;
        border: none;
        width: 100%;
        height: 0.36rem;
        font-size: 0.14rem;
        background: none;
        color: var(--gray-10);
      }
    }
    &.isDark {
      border: 0.01rem solid #2a2b2f;
      .inputSubWrapple  {
        .searchIcon {
          width: 0.24rem;
          height: 0.24rem;
          background: url("../assets/search_dark.png") no-repeat;
          background-size: cover;
        }
      }
    }
  }
  .chain-list-wrap {
    margin-top: 0.05rem;
    .chain-item {
      padding: 0.1rem 0.15rem 0.1rem 0.1rem;
      &.isResult {
        padding: 0.1rem;
      }
      cursor: pointer;
      .left {
        img {
          width: 0.3rem;
        }
        span {
          margin-left: 0.1rem;
          font-size: 0.14rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: var(--gray-10);
        }
      }
      .right {
        width: 0.06rem;
        // height: 0.11rem;
      }
    }
    .chain-item:hover {
      background: var(--chain-list-item);
      border-radius: 0.26rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .chain-list {
    width: 100%;
    top: 1.28rem;
    max-height: 6rem;
    border-radius: 0.16rem;
    padding: 0.32rem;
    &.isResult {
      width: 100%;
      max-height: 6.4rem;
      top: 1rem;
    }
    .listsearchWrape {
      border: 0.01rem solid #e9e9e9;
      border-radius: 0.28rem;
      .inputSubWrapple {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        overflow: hidden;
        input::placeholder {
          color: #bbb;
        }
        .searchIcon {
          width: 0.32rem;
          height: 0.32rem;
          background: url("../assets/search_light@2x.png") no-repeat;
          background-size: cover;
        }
        input {
          margin-left: 0.12rem;
          border: none;
          width: 100%;
          height: 0.72rem;
          font-size: 0.26rem;
        }
      }
      &.isDark {
        border: 0.01rem solid #2a2b2f;
        .inputSubWrapple {
          .searchIcon {
            width: 0.32rem;
            height: 0.32rem;
            background: url("../assets/search_dark.png") no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .empty-view {
      width: 100%;
      min-height: 3rem;
      display: flex;
      direction: flex-column;
      justify-content: center;
      align-items: center;
      .empty-in {
        width: 0.9rem;
        height: 0.9rem;
        background: url(../assets/no-result.png);
        background-size: cover;
      }
      .empty-text {
        margin-top: 0.15rem;
        color: #777;
        font-size: 0.24rem;
      }
    }
    .chain-list-wrap {
      margin-top: 0.1rem;
      .chain-item {
        padding: 0.15rem 0;
        .left {
          img {
            width: 0.62rem;
            &.isResult {
              width: 0.56rem;
              height: 0.56rem;
            }
          }
          span {
            margin-left: 0.2rem;
            font-size: 0.26rem;
            &.isResult {
              font-size: 0.24rem;
            }
          }
        }
        .right {
          width: 0.09rem;
        }
      }
      .chain-item:hover {
        background: none;
      }
    }
  }
}
</style>
