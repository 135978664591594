import { add } from 'lodash';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toast from '../components/Toast.vue';
// import Web3 from 'web3'
import {
  chainIdBrowserMap,
  chainIdBrowserEnMap,
  chainIdBrowserTokenMap,
  chainIdBrowserTokenEnMap,
  blockchainToChainId,
  holeAddressList,
  SERVER_URL,
  FORWARD_SERVER_URL,
} from '../utils';

const enuTrade = [
  'is_honeypot',
  'slippage_modifiable',
  'transfer_pausable',
  'is_blacklisted',
  'is_whitelisted',
  'is_anti_whale',
  'cannot_buy',
  'cannot_sell_all',
  'trading_cooldown',
  'personal_slippage_modifiable',
  'anti_whale_modifiable',
];
const enuContract = [
  'is_airdrop_scam',
  'is_true_token',
  'is_open_source',
  'is_proxy',
  'is_mintable',
  'owner_change_balance',
  'hidden_owner',
  'can_take_back_ownership',
  'selfdestruct',
  'external_call',
];

const enuTax = ['buy_tax', 'sell_tax'];

export const GlobalMixins = {
  data() {
    return {
      address: '',
      toastTitleObj: {},
      tokenInfo: null,
      holdersPercent: 0,
      lpHoldersPercent: 0,
      lockedPercent: 0,
      isToast: false,
      isLoading: false,
      isDataError: false,
      isCopyContract: false,
      isHoleAddress: false,

      tradeList: [],
      contractList: [],
      taxList: [],
      chainList: [
        {
          icon: require('../assets/chain-icon/01.png'),
          blockchain_id: 1,
          label: 'Ethereum',
          ns: 'ethereum',
          chain_id: '1',
        },
        {
          icon: require('../assets/chain-icon/02.png'),
          blockchain_id: 12,
          label: 'BSC',
          ns: 'ethereum',
          chain_id: '56',
        },
        {
          icon: require('../assets/chain-icon/tron.png'),
          blockchain_id: 10,
          label: 'Tron',
          ns: 'tron',
          chain_id: '728126428',
        },
        {
          icon: require('../assets/chain-icon/05.png'),
          blockchain_id: 18,
          label: 'Polygon',
          ns: 'ethereum',
          chain_id: '137',
        },
        {
          icon: require('../assets/chain-icon/blast.png'),
          blockchain_id: 65,
          label: 'Blast',
          ns: 'ethereum',
          chain_id: '81457',
        },
        {
          icon: require('../assets/chain-icon/08.png'),
          blockchain_id: 24,
          label: 'Arbitrum',
          ns: 'ethereum',
          chain_id: '42161',
        },
        {
          icon: require('../assets/chain-icon/op.png'),
          blockchain_id: 26,
          label: 'Optimism',
          ns: 'ethereum',
          chain_id: '10',
        },
        {
          icon: require('../assets/chain-icon/zkSync-Era.png'),
          blockchain_id: 46,
          label: 'zkSync Era',
          ns: 'ethereum',
          chain_id: '324',
        },
        {
          icon: require('../assets/chain-icon/Bitlayer.png'),
          blockchain_id: 66,
          label: 'Bitlayer',
          ns: 'ethereum',
          chain_id: '200901',
        },
        {
          icon: require('../assets/chain-icon/base.png'),
          blockchain_id: 51,
          label: 'Base',
          ns: 'ethereum',
          chain_id: '8453',
        },
        {
          icon: require('../assets/chain-icon/linea.png'),
          label: 'Linea',
          ns: 'ethereum',
          chain_id: '59144',
        },
        {
          icon: require('../assets/chain-icon/Scroll.png'),
          blockchain_id: 56,
          label: 'Scroll',
          ns: 'ethereum',
          chain_id: '534352',
        },
        {
          icon: require('../assets/chain-icon/mantle.png'),
          blockchain_id: 49,
          label: 'Mantle',
          ns: 'ethereum',
          chain_id: '5000',
        },
        {
          icon: require('../assets/chain-icon/07.png'),
          blockchain_id: 25,
          label: 'Fantom',
          ns: 'ethereum',
          chain_id: '250',
        },
        {
          icon: require('../assets/chain-icon/03.png'),
          blockchain_id: 15,
          label: 'HECO',
          ns: 'ethereum',
          chain_id: '128',
        },
        {
          icon: require('../assets/chain-icon/opBNB.png'),
          blockchain_id: 52,
          label: 'opBNB',
          ns: 'ethereum',
          chain_id: '204',
        },
        {
          icon: require('../assets/chain-icon/04.png'),
          blockchain_id: 20,
          label: 'OKExChain',
          ns: 'ethereum',
          chain_id: '66',
        },
        {
          icon: require('../assets/chain-icon/06.png'),
          blockchain_id: 23,
          label: 'Avalanche',
          ns: 'ethereum',
          chain_id: '43114',
        },
        {
          icon: require('../assets/chain-icon/kcc.png'),
          blockchain_id: 35,
          label: 'KCC',
          ns: 'ethereum',
          chain_id: '321',
        },
        {
          icon: require('../assets/chain-icon/10.png'),
          blockchain_id: 33,
          label: 'Harmony',
          ns: 'ethereum',
          chain_id: '1666600000',
        },
        {
          icon: require('../assets/chain-icon/manta.png'),
          blockchain_id: 58,
          label: 'Manta',
          ns: 'ethereum',
          chain_id: '169',
        },
        {
          icon: require('../assets/chain-icon/ZKFair.png'),
          blockchain_id: 57,
          label: 'ZKFair',
          ns: 'ethereum',
          chain_id: '42766',
        },
        {
          icon: require('../assets/chain-icon/merlin.png'),
          blockchain_id: 64,
          label: 'Merlin',
          ns: 'ethereum',
          chain_id: '4200',
        },
        {
          icon: require('../assets/chain-icon/Gnosis.png'),
          blockchain_id: 28,
          label: 'Gnosis',
          ns: 'ethereum',
          chain_id: '100',
        },
        {
          icon: require('../assets/chain-icon/Cronos.png'),
          label: 'Cronos',
          ns: 'ethereum',
          chain_id: '25',
        },
        {
          icon: require('../assets/chain-icon/fon.png'),
          label: 'Fon',
          ns: 'ethereum',
          chain_id: '201022',
        },
        {
          icon: require('../assets/chain-icon/ethw.png'),
          label: 'ETHW',
          ns: 'ethereum',
          chain_id: '10001',
        },
      ],
      // trade_0_Map: {
      //   is_honeypot: this.$t('trade_0_text1'),
      //   slippage_modifiable: this.$t('trade_0_text2'),
      //   transfer_pausable: this.$t('trade_0_text3'),
      //   is_blacklisted: this.$t('trade_0_text4'),
      //   is_whitelisted: this.$t('trade_0_text5'),
      //   is_anti_whale: this.$t('trade_0_text6'),
      //   cannot_buy: this.$t('trade_0_text7'),
      //   cannot_sell_all: this.$t('trade_0_text8'),
      //   trading_cooldown: this.$t('trade_0_text9'),
      //   personal_slippage_modifiable: this.$t('trade_0_text10'),
      //   anti_whale_modifiable: this.$t('trade_0_text11'),
      // },
      // trade_1_Map: {
      //   is_honeypot: this.$t('trade_1_text1'),
      //   slippage_modifiable: this.$t('trade_1_text2'),
      //   transfer_pausable: this.$t('trade_1_text3'),
      //   is_blacklisted: this.$t('trade_1_text4'),
      //   is_whitelisted: this.$t('trade_1_text5'),
      //   is_anti_whale: this.$t('trade_1_text6'),
      //   cannot_buy: this.$t('trade_1_text7'),
      //   cannot_sell_all: this.$t('trade_1_text8'),
      //   trading_cooldown: this.$t('trade_1_text9'),
      //   personal_slippage_modifiable: this.$t('trade_1_text10'),
      //   anti_whale_modifiable: this.$t('trade_1_text11'),
      // },
      // trade_2_HoleAddress_Map: {
      //   transfer_pausable: this.$t('trade_2_text1'),
      //   slippage_modifiable: this.$t('trade_2_text2'),
      //   trading_cooldown: this.$t('trade_2_text3'),
      //   anti_whale_modifiable: this.$t('trade_2_text4'),
      // },
      // contract_0_Map: {
      //   is_airdrop_scam: this.$t('airdropScam'),
      //   is_true_token: this.$t('fakeToken'),
      //   is_open_source: this.$t('contract_0_text1'),
      //   is_proxy: this.$t('contract_0_text2'),
      //   is_mintable: this.$t('contract_0_text3'),
      //   owner_change_balance: this.$t('contract_0_text4'),
      //   hidden_owner: this.$t('contract_0_text5'),
      //   can_take_back_ownership: this.$t('contract_0_text6'),
      //   selfdestruct: this.$t('contract_0_text7'),
      //   external_call: this.$t('contract_0_text8'),
      // },
      // contract_1_Map: {
      //   is_airdrop_scam: this.$t('airdropScam'),
      //   is_true_token: this.$t('fakeToken'),
      //   is_open_source: this.$t('contract_1_text1'),
      //   is_proxy: this.$t('contract_1_text2'),
      //   is_mintable: this.$t('contract_1_text3'),
      //   owner_change_balance: this.$t('contract_1_text4'),
      //   hidden_owner: this.$t('contract_1_text5'),
      //   can_take_back_ownership: this.$t('contract_1_text6'),
      //   selfdestruct: this.$t('contract_1_text7'),
      //   external_call: this.$t('contract_1_text8'),
      // },
      // contract_2_HoleAddress_Map: {
      //   is_mintable: this.$t('contract_2_text1'),
      //   owner_change_balance: this.$t('contract_2_text2'),
      // },
    };
  },
  components: { Loading, Toast },

  computed: {
    trade_0_Map() {
      return {
        is_honeypot: this.$t('trade_0_text1'),
        slippage_modifiable: this.$t('trade_0_text2'),
        transfer_pausable: this.$t('trade_0_text3'),
        is_blacklisted: this.$t('trade_0_text4'),
        is_whitelisted: this.$t('trade_0_text5'),
        is_anti_whale: this.$t('trade_0_text6'),
        cannot_buy: this.$t('trade_0_text7'),
        cannot_sell_all: this.$t('trade_0_text8'),
        trading_cooldown: this.$t('trade_0_text9'),
        personal_slippage_modifiable: this.$t('trade_0_text10'),
        anti_whale_modifiable: this.$t('trade_0_text11'),
      }
    },
    trade_1_Map() {
      return {
        is_honeypot: this.$t('trade_1_text1'),
        slippage_modifiable: this.$t('trade_1_text2'),
        transfer_pausable: this.$t('trade_1_text3'),
        is_blacklisted: this.$t('trade_1_text4'),
        is_whitelisted: this.$t('trade_1_text5'),
        is_anti_whale: this.$t('trade_1_text6'),
        cannot_buy: this.$t('trade_1_text7'),
        cannot_sell_all: this.$t('trade_1_text8'),
        trading_cooldown: this.$t('trade_1_text9'),
        personal_slippage_modifiable: this.$t('trade_1_text10'),
        anti_whale_modifiable: this.$t('trade_1_text11'),
      }
    },
    trade_2_HoleAddress_Map() {
      return {
        transfer_pausable: this.$t('trade_2_text1'),
        slippage_modifiable: this.$t('trade_2_text2'),
        trading_cooldown: this.$t('trade_2_text3'),
        anti_whale_modifiable: this.$t('trade_2_text4'),
      }
    },
    contract_0_Map() {
      return {
        is_airdrop_scam: this.$t('airdropScam'),
        is_true_token: this.$t('fakeToken'),
        is_open_source: this.$t('contract_0_text1'),
        is_proxy: this.$t('contract_0_text2'),
        is_mintable: this.$t('contract_0_text3'),
        owner_change_balance: this.$t('contract_0_text4'),
        hidden_owner: this.$t('contract_0_text5'),
        can_take_back_ownership: this.$t('contract_0_text6'),
        selfdestruct: this.$t('contract_0_text7'),
        external_call: this.$t('contract_0_text8'),
      }
    },
    contract_1_Map() {
      return {
        is_airdrop_scam: this.$t('airdropScam'),
        is_true_token: this.$t('fakeToken'),
        is_open_source: this.$t('contract_1_text1'),
        is_proxy: this.$t('contract_1_text2'),
        is_mintable: this.$t('contract_1_text3'),
        owner_change_balance: this.$t('contract_1_text4'),
        hidden_owner: this.$t('contract_1_text5'),
        can_take_back_ownership: this.$t('contract_1_text6'),
        selfdestruct: this.$t('contract_1_text7'),
        external_call: this.$t('contract_1_text8'),
      }
    },
    contract_2_HoleAddress_Map() {
      return {
        is_mintable: this.$t('contract_2_text1'),
        owner_change_balance: this.$t('contract_2_text2'),
      }
    },
    risky() {
      const arr1 = this.tradeList.filter((t) => t.status == 2);
      const arr2 = this.contractList.filter((t) => t.status == 2);
      const arr3 = this.taxList.filter((t) => t.status == 2);
      return arr1.length + arr2.length + arr3.length;
    },
    attention() {
      const arr1 = this.tradeList.filter((t) => t.status == 1);
      const arr2 = this.contractList.filter((t) => t.status == 1);
      const arr3 = this.taxList.filter((t) => t.status == 1);
      // 这里处理费率为空也就是未知税率的情况
      const unknown_buy_tax = this.tokenInfo.unknown_buy_tax ? 1 : 0
      const unknown_sell_tax = this.tokenInfo.unknown_sell_tax ? 1 : 0
      return arr1.length + arr2.length + arr3.length + unknown_buy_tax + unknown_sell_tax;
    },
  },

  methods: {
    /** 获取代币的状态 */
    async getTokenStatus(address, blockchain_id, bl_symbol) {
      try {
        const response = await fetch(`${SERVER_URL}/v1/token?blockchain_id=${blockchain_id}&bl_symbol=${bl_symbol}&address=${address}`)
        const res = await response.json()
        if (res.result === 0 && res.data) {
          const obj = {
            label: this.$t('trade_token_status_text1'),
            status: 2,
          }
          if (res.data.token_status === 2 || res.data.token_status === 4) {
            this.tradeList.unshift(obj)
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onBlockchainBrowser(address, chain_id, blockchain_id, isToken) {
      // console.log('address', address);
      // console.log('chain_id', chain_id);
      // console.log('blockchain_id', blockchain_id);
      // console.log('isToken', isToken);
      if (chain_id) {
        if (isToken) {
          window.location.href = this.getUrlById(chain_id, 0, address)
        } else {
          window.location.href = this.getUrlById(chain_id, 1, address)
        }
      } else if (!chain_id && blockchain_id) {
        const chainId = blockchainToChainId[blockchain_id]
        if (isToken) {
          window.location.href = this.getUrlById(chainId, 0, address)
        } else {
          window.location.href = this.getUrlById(chainId, 1, address)
        }
      }
    },
    getUrlById(id, type, address) {
      let url = ''
      if (type === 0) {
        if (this.$i18n.locale === 'zh') {
          url = chainIdBrowserTokenMap[id] + address + (id == '1' ? '?hmsr=tpwallet' : '')
        } else {
          url = chainIdBrowserTokenEnMap[id] + address
        }
      } else if (type === 1) {
        if (this.$i18n.locale === 'zh') {
          url = chainIdBrowserMap[id] + address + (id == '1' ? '?hmsr=tpwallet' : '')
        } else {
          url = chainIdBrowserEnMap[id] + address
        }
      }
      return url
    },
    onCopy(text, flag) {
      this.toastTitleObj = {};
      this.$copyText(text);
      if (flag) {
        this.isToast = true;
        this.isCopyContract = true;
      } else {
        if (!this.isToast) {
          this.isToast = true;
          setTimeout(() => {
            this.isToast = false;
            this.isCopyContract = false;
          }, 1500);
        }
      }
    },
    closeToast() {
      this.isToast = false;
      this.isCopyContract = false;
    },
    // 判断一个地址是否是合约
    async getCode(address, node, chain_id) {
      // console.log('address', address);
      // console.log('node', node);
      // console.log('chain_id', chain_id);
      let data = {};
      let url = node;
      if (chain_id == '728126428') {
        data = {
          value: address,
          visible: true,
        };
        url += '/wallet/getcontract';
      } else {
        data = {
          id: 1,
          jsonrpc: '2.0',
          method: 'eth_getCode',
          params: [address, 'latest'],
        };
      }
      try {
        const response = await fetch(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const res = await response.json();
        if (
          (res.result && res.result.length > 5) ||
          (res && res.bytecode.length > 5)
        ) {
          return true;
        } else {
          this.isLoading = false;
          return false;
        }
      } catch (error) {
        this.isLoading = false;
        // console.log('error', error)
        return true;
      }
    },
    async tokenSecurity(address, blockchain_id, chainData) {
      this.tokenInfo = null;
      this.isLoading = true;
      this.holdersPercent = 0;
      this.lpHoldersPercent = 0;
      this.lockedPercent = 0;
      let address1 = address.trim();
      if (blockchain_id != 10) {
        address1 = address1.toLowerCase();
      }
      try {
        let response = ''
        if (chainData.ns && chainData.chain_id) {
          response = await fetch(
            `${FORWARD_SERVER_URL}/v1/token/token_security?address=${address1}&ns=${chainData.ns}&chain_id=${chainData.chain_id}`
          );
        } else {
          response = await fetch(
            `${FORWARD_SERVER_URL}/v1/token/token_security?address=${address1}&blockchain_id=${blockchain_id}`
          );
        }
        const res = await response.json();
        if (res.result === 0 && res.data !== null) {
          this.initData(res.data);
        } else {
          throw new Error('error');
        }
      } catch (error) {
        console.log('出错了');
        // this.isDataError = true;
        // this.isLoading = false;
        this.getGoTokenSecurity(address, chainData.chain_id);
      }
    },
    // 转接地址请求失败之后使用go+接口
    async getGoTokenSecurity(address, chain_id) {
      if (chain_id === '728126428') {
        chain_id = 'tron'
      }
      const response = await fetch(
        `https://api.gopluslabs.io/api/v1/token_security/${chain_id}?contract_addresses=${address}`
      );
      const res = await response.json();
      // console.log('getGoTokenSecurity', res.result[this.address]);
      let address1 = this.address;
      if (this.blockchain_id != 10) {
        address1 = address1.toLowerCase();
      }
      if (res.message === 'OK' && res.result && res.result[address1]) {
        // this.isDataError = false;
        // this.isLoading = true;
        this.initData(res.result[address1]);
      } else {
        this.isDataError = true;
      }
      this.isLoading = false;
    },
    initData(data) {
      // console.log('data', data);
      data.address = this.address;
      this.tokenInfo = data || {};
      // if (!this.tokenInfo.creator_address) {
      //   throw new Error('error')
      // }
      if (this.tokenInfo.holders && this.tokenInfo.holders.length) {
        this.tokenInfo.holders.forEach((el) => {
          this.holdersPercent += Number(el.percent) * 100;
        });
        if (this.holdersPercent > 100) {
          this.holdersPercent = 100;
        }
      }
      if (this.tokenInfo.lp_holders && this.tokenInfo.lp_holders.length) {
        this.tokenInfo.lp_holders.forEach((el) => {
          this.lpHoldersPercent += Number(el.percent) * 100;
          if (el.is_locked) {
            this.lockedPercent += Number(el.percent) * 100;
          }
        });
        if (this.lpHoldersPercent > 100) {
          this.lpHoldersPercent = 100;
        }
        if (this.lockedPercent > 100) {
          this.lockedPercent = 100;
        }
      }

      if (this.tokenInfo.buy_tax) {
        this.tokenInfo.buy_tax = (
          Math.floor(this.tokenInfo.buy_tax * 10000) / 100
        ).toFixed(2);
      } else if (this.tokenInfo.buy_tax === '') {
        this.tokenInfo.buy_tax = this.$t('unknown_tax')
        this.tokenInfo.unknown_buy_tax = true
      }
      if (this.tokenInfo.sell_tax) {
        this.tokenInfo.sell_tax = (
          Math.floor(this.tokenInfo.sell_tax * 10000) / 100
        ).toFixed(2);
      } else if (this.tokenInfo.sell_tax === '') {
        this.tokenInfo.sell_tax = this.$t('unknown_tax')
        this.tokenInfo.unknown_sell_tax = true
      }

      // console.log('tokenInfo', this.tokenInfo)
      const { owner_address, hidden_owner, can_take_back_ownership } =
        this.tokenInfo;
      this.isHoleAddress = this.ownerSecurity(
        owner_address,
        hidden_owner,
        can_take_back_ownership
      );
      // console.log('isHoleAddress', this.isHoleAddress)
      this.createTradeList(this.tokenInfo);
      this.createContractList(this.tokenInfo);
      this.cerateTaxList(this.tokenInfo);
      this.isLoading = false;
    },
    // owner为黑洞, hidden_owner为0, can_take_back_ownership为0同时满足时，
    // 表格中“受owner权限影响”中打v的，不进行安全风险统计，也不进行展示。
    ownerSecurity(owner_address, hidden_owner, can_take_back_ownership) {
      if (owner_address && hidden_owner && can_take_back_ownership) {
        if (
          holeAddressList.indexOf(owner_address.toLowerCase()) > -1 &&
          hidden_owner == '0' &&
          can_take_back_ownership == '0'
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    createTradeList(tokenInfo) {
      this.tradeList = [];
      enuTrade.forEach((item) => {
        if (tokenInfo[item]) {
          // console.log('trade--', item, 'value--', tokenInfo[item])
          let obj = {};
          if (tokenInfo[item] == 0) {
            obj.label = this.trade_0_Map[item];
            obj.status = 0;
          } else {
            obj.label = this.trade_1_Map[item];
            obj.status = 1;
            if (item === 'is_honeypot') {
              obj.status = 2;
            }
          }
          obj[item] = tokenInfo[item];
          if (
            (item === 'cannot_buy' && tokenInfo['cannot_buy'] == 0) ||
            (item === 'cannot_sell_all' && tokenInfo['cannot_sell_all'] == 0) ||
            (this.isHoleAddress &&
              (item === 'is_blacklisted' ||
                item === 'is_whitelisted' ||
                item === 'anti_whale_modifiable'))
          ) {
            console.log('不存入--', item);
          } else {
            if (
              this.isHoleAddress &&
              (item === 'slippage_modifiable' ||
                item === 'transfer_pausable' ||
                item === 'trading_cooldown')
            ) {
              console.log('存入', item);
              obj.label = this.trade_2_HoleAddress_Map[item];
              obj.status = 0;
            }
            // item === 'transfer_pausable' ||
            // item === 'trading_cooldown' ||
            // item === 'slippage_modifiable' ||
            this.tradeList.push(obj);
          }
        }
      });
      this.tradeList.sort((a, b) => b.status - a.status);
    },
    createContractList(tokenInfo) {
      this.contractList = [];
      enuContract.forEach((item) => {
        if (tokenInfo[item]) {
          // console.log('contract--', item, 'value--', tokenInfo[item])
          let obj = {};
          // owner为黑洞, hidden_owner为0, can_take_back_ownership为0同时满足时，可以判定为owner权限已丢弃
          // 不管数据是 0 还是 1 都是没有风险, 增发和修改余额的描述改变,
          if (
            this.isHoleAddress &&
            (item === 'is_mintable' || item === 'owner_change_balance')
          ) {
            obj.label = this.contract_2_HoleAddress_Map[item];
            obj.status = 0;
            this.contractList.push(obj);
          }
          // 这个几个属性是为 1 的时候是安全的
          else if (item === 'is_open_source' || item === 'is_true_token') {
            if (tokenInfo[item] == 1) {
              obj.label = this.contract_0_Map[item];
              obj[item] = obj.status = 0;
              if (item === 'is_open_source') {
                this.contractList.push(obj);
              }
            } else {
              obj.label = this.contract_1_Map[item];
              obj[item] = obj.status = 2;
              this.contractList.push(obj);
            }
          }
          // 这个几个属性是为 0 的时候是安全的
          else {
            if (tokenInfo[item] == 0) {
              obj.label = this.contract_0_Map[item];
              obj[item] = obj.status = tokenInfo[item];
              if (item !== 'is_airdrop_scam') {
                this.contractList.push(obj);
              }
            } else {
              obj[item] = obj.status = tokenInfo[item];
              obj.label = this.contract_1_Map[item];
              if (
                item === 'is_open_source' ||
                item === 'is_airdrop_scam' ||
                item === 'selfdestruct'
              ) {
                obj.status = 2;
              } else {
                // 显示警告
                obj.status = 1;
              }
              this.contractList.push(obj);
            }
          }
        }
      });
      this.contractList.sort((a, b) => b.status - a.status);
    },
    cerateTaxList(tokenInfo) {
      this.taxList = [];
      enuTax.forEach((item) => {
        if (tokenInfo[item]) {
          let obj = {};
          if (tokenInfo[item] >= 10 && tokenInfo[item] < 50) {
            obj.label = this.$t(item) + ' ' + tokenInfo[item] + '%';
            obj.status = 1;
            this.taxList.push(obj);
          } else if (tokenInfo[item] >= 50) {
            obj.label = this.$t(item) + ' ' + tokenInfo[item] + '%';
            obj.status = 2;
            this.taxList.push(obj);
          }
        }
      });
    },
  },
};
