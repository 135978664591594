
export const SERVER_URL = 'https://preserver.mytokenpocket.vip'
export const FORWARD_SERVER_URL = 'https://forward.mytokenpocket.vip'

export const NodeMap = {
  1: 'https://web3.mytokenpocket.vip', //eth
  728126428: 'https://trx.mytokenpocket.vip', // tron
  56: 'https://bsc-dataseed4.ninicoin.io', // bsc
  128: 'https://http-mainnet.hecochain.com', // heco
  137: 'https://matic-mainnet.chainstacklabs.com', // Polygon
  66: 'https://okchain.mytokenpocket.vip', // okex
  43114: 'https://api.avax.network/ext/bc/C/rpc', // Avalanche
  42161: 'https://arb1.arbitrum.io/rpc', // Arbitrum
  250: 'https://rpcapi.fantom.network', // Fantom
  10: 'https://mainnet.optimism.io/', // Optimism
  100: 'https://rpc.ankr.com/gnosis', // Gnosis
  1666600000: 'https://api.harmony.one', // Harmony
  321: 'https://kcc.mytokenpocket.vip', // KCC
  59140: 'https://explorer.goerli.linea.build/api', // linea
  201022: 'https://fsc-dataseed1.fonscan.io', // Fon
  25: 'https://evm.cronos.org', // Cronos
  10001: 'https://mainnet.ethereumpow.orgorg', // ETHW
  324: 'https://zksync2-mainnet.zksync.io', // zkSync
  204: 'https://opbnb-mainnet-rpc.bnbchain.org/', // opBNB
  534352: 'https://rpc.scroll.io/', // Scroll
  8453: 'https://base.drpc.org', // base
  169: 'https://1rpc.io/manta', // Manta
  81457: 'https://blast.blockpi.network/v1/rpc/public', // blast
  42766: 'https://rpc.zkfair.io', // ZKFirm
  4200: 'https://rpc.merlinchain.io', // Merlin
  200901: 'https://rpc.ankr.com/bitlayer', // Bitlayer
};

// address
export const chainIdBrowserMap = {
  1: 'https://www.oklink.com/cn/eth/address/',
  728126428: 'https://tronscan.io/#/contract/',
  56: 'https://bscscan.com/address/',
  128: 'https://hecoinfo.com/address/',
  137: 'https://polygonscan.com/address/',
  66: 'https://www.oklink.com/en/okc/address/',
  43114: 'https://snowtrace.io/address/',
  42161: 'https://arbiscan.io/address/',
  250: 'https://ftmscan.com/address/',
  10: 'https://optimistic.etherscan.io/address/',
  100: 'https://gnosisscan.io/address/',
  1666600000: 'https://explorer.harmony.one/address/',
  321: 'https://scan.kcc.io/address/',
  59140: 'https://explorer.goerli.linea.build/address/',
  201022: 'https://fonscan.io/address/',
  25: 'https://cronoscan.com/token/', // Cronos
  10001: 'https://www.oklink.com/ethw/token/', // ETHW
  324: 'https://explorer.zksync.io/address/', // zkSync
  534352: 'https://blockscout.scroll.io/address/', // Scroll
  204: 'https://opbnbscan.com/address/', // opBNB
  8453: 'https://basescan.org/address/', // base
  169: 'https://pacific-explorer.manta.network/address/', // Manta
  81457: 'https://blastscan.io/address/', // blast
  5000: 'https://explorer.mantle.xyz/address/', // Mantle
  42766: 'https://scan.zkfair.io/address/', // ZKFirm
  4200: 'https://scan.merlinchain.io/address/', // Merlin
  200901: 'https://www.btrscan.com/address/', // Bitlayer
};

// address
export const chainIdBrowserEnMap = {
  1: 'https://etherscan.io/address/',
  728126428: 'https://tronscan.io/#/contract/',
  56: 'https://bscscan.com/address/',
  128: 'https://hecoinfo.com/address/',
  137: 'https://polygonscan.com/address/',
  66: 'https://www.oklink.com/en/okc/address/',
  43114: 'https://snowtrace.io/address/',
  42161: 'https://arbiscan.io/address/',
  250: 'https://ftmscan.com/address/',
  10: 'https://optimistic.etherscan.io/address/',
  100: 'https://gnosisscan.io/address/',
  1666600000: 'https://explorer.harmony.one/address/',
  321: 'https://scan.kcc.io/address/',
  59140: 'https://explorer.goerli.linea.build/address/',
  201022: 'https://fonscan.io/address/',
  25: 'https://cronoscan.com/token/', // Cronos
  10001: 'https://www.oklink.com/ethw/token/', // ETHW
  324: 'https://explorer.zksync.io/address/', // zkSync
  534352: 'https://blockscout.scroll.io/address/', // Scroll
  204: 'https://opbnbscan.com/address/', // opBNB
  8453: 'https://basescan.org/address/', // base
  169: 'https://pacific-explorer.manta.network/address/', // Manta
  81457: 'https://blastscan.io/address/', // blast
  5000: 'https://explorer.mantle.xyz/address/', // Mantle
  42766: 'https://scan.zkfair.io/address/', // ZKFirm
  4200: 'https://scan.merlinchain.io/address/', // Merlin
  200901: 'https://www.btrscan.com/address/', // Bitlayer
};

// token
export const chainIdBrowserTokenMap = {
  1: 'https://www.oklink.com/cn/eth/token/',
  728126428: 'https://tronscan.io/#/token20/',
  56: 'https://bscscan.com/token/',
  128: 'https://hecoinfo.com/token/',
  137: 'https://polygonscan.com/token/',
  66: 'https://www.oklink.com/en/okc/token/',
  43114: 'https://snowtrace.io/token/',
  42161: 'https://arbiscan.io/token/',
  250: 'https://ftmscan.com/token/',
  10: 'https://optimistic.etherscan.io/token/',
  100: 'https://gnosisscan.io/token/',
  1666600000: 'https://explorer.harmony.one/address/',
  321: 'https://scan.kcc.io/token/',
  59140: 'https://explorer.goerli.linea.build/token/',
  201022: 'https://fonscan.io/token/',
  25: 'https://cronoscan.com/token/', // Cronos
  10001: 'https://www.oklink.com/ethw/token/', // ETHW
  324: 'https://explorer.zksync.io/address/', // zkSync
  204: 'https://opbnbscan.com/token/', // opBNB
  534352: 'https://blockscout.scroll.io/token/', // Scroll
  8453: 'https://basescan.org/token/', // base
  169: 'https://pacific-explorer.manta.network/token/', // Manta
  81457: 'https://blastscan.io/token/', // blast
  5000: 'https://explorer.mantle.xyz/token/', // Mantle
  42766: 'https://scan.zkfair.io/token/', // ZKFirm
  4200: 'https://scan.merlinchain.io/token/', // Merlin
  200901: 'https://www.btrscan.com/token/', // Bitlayer
};

// token
export const chainIdBrowserTokenEnMap = {
  1: 'https://etherscan.io/token/',
  728126428: 'https://tronscan.io/#/token20/',
  56: 'https://bscscan.com/token/',
  128: 'https://hecoinfo.com/token/',
  137: 'https://polygonscan.com/token/',
  66: 'https://www.oklink.com/en/okc/token/',
  43114: 'https://snowtrace.io/token/',
  42161: 'https://arbiscan.io/token/',
  250: 'https://ftmscan.com/token/',
  10: 'https://optimistic.etherscan.io/token/',
  100: 'https://gnosisscan.io/token/',
  1666600000: 'https://explorer.harmony.one/address/',
  321: 'https://scan.kcc.io/token/',
  59140: 'https://explorer.goerli.linea.build/token/',
  201022: 'https://fonscan.io/token/',
  25: 'https://cronoscan.com/token/', // Cronos
  10001: 'https://www.oklink.com/ethw/token/', // ETHW
  324: 'https://explorer.zksync.io/address/', // zkSync
  534352: 'https://blockscout.scroll.io/token/', // Scroll
  204: 'https://blockscout.scroll.io/address/', // opBNB
  8453: 'https://basescan.org/token/', // base
  169: 'https://pacific-explorer.manta.network/token/', // Manta
  81457: 'https://blastscan.io/token/', // blast
  5000: 'https://explorer.mantle.xyz/token/', // Mantle
  42766: 'https://scan.zkfair.io/token/', // ZKFirm
  4200: 'https://scan.merlinchain.io/token/', // Merlin
  200901: 'https://www.btrscan.com/token/', // Bitlayer
};

export const blockchainToChainId = {
  1: '1',
  10: '728126428',
  12: '56',
  15: '128',
  18: '137',
  20: '66',
  23: '43114', //avax
  24: '42161',
  25: '250',
  26: '10',
  28: '100', //gnosis
  33: '1666600000',
  35: '321', //KCC
  46: '324', //zkSync
  52: '204', //opBNB
  56: '534352', //Scroll
  51: '8453', //base
  58: '169', //Manta
  65: '81457', // blast
  49: '5000', // Mantle
  57: '42766', // ZKFirm
  64: '4200', // Merlin
  66: '200901', // Bitlayer
  // 59140: 'https://explorer.goerli.linea.build/address/',
  // 201022: 'https://fonscan.io/address/',
  // 25: 'https://cronoscan.com/token/', // Cronos
  // 10001: 'https://www.oklink.com/ethw/token/', // ETHW
};

export const holeAddressList = [
  '0x0000000000000000000000000000000000000000',
  '0x0000000000000000000000000000000000000001',
  '0x0000000000000000000000000000000000000002',
  '0x0000000000000000000000000000000000000003',
  '0x0000000000000000000000000000000000000004',
  '0x0000000000000000000000000000000000000005',
  '0x0000000000000000000000000000000000000006',
  '0x0000000000000000000000000000000000000007',
  '0x0000000000000000000000000000000000000008',
  '0x0000000000000000000000000000000000000009',
  '0x000000000000000000000000000000000000000a',
  '0x000000000000000000000000000000000000000b',
  '0x000000000000000000000000000000000000000c',
  '0x000000000000000000000000000000000000000d',
  '0x000000000000000000000000000000000000000e',
  '0x000000000000000000000000000000000000dead',
]

export function setMode(mode) {
  if (mode) {
    document.documentElement.setAttribute('theme', 'dark');
  } else {
    document.documentElement.removeAttribute('theme');
  }
}

export const CHAIN_INFO_MAP = {
  ETH: {
    WRAP_TOKENS: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    BAL_CONTRACT: '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [
          'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        ],
        blockExplorerUrls: ['https://cn.etherscan.com/'],
      },
    ],
    blockchain_id: '1',
    explorer: 'http://etherscan.io/tx/',
    addressExplorer: 'https://etherscan.com/address/{account}',
    isEVM: true,
    CHAIN_ID: 1,
    stableTokens: [
      '0xdac17f958d2ee523a2206206994597c13d831ec7',
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    ],
  },
  BSC: {
    WRAP_TOKENS: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
    BAL_CONTRACT: '0xe83ff9a4b82b5d5cb5256e805c564f0a663e3080',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      // approveProxy: '0xeD1afC8C4604958C2F38a3408FA63B32E737c428',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x38',
        chainName: 'BNB Chain',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed3.defibit.io'],
        blockExplorerUrls: ['https://bscscan.com/'],
      },
    ],
    blockchain_id: '12',
    explorer: 'https://bscscan.com/tx/',
    addressExplorer: 'https://bscscan.com/address/{account}',
    isEVM: true,
    CHAIN_ID: 56,
    stableTokens: [
      '0x55d398326f99059ff775485246999027b3197955',
      '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    ],
  },
  HECO: {
    WRAP_TOKENS: ['0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F'],
    BAL_CONTRACT: '0xa7f46a7bd0be90cd5dc39b906108443bcb30b418',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      // approveProxy: '0xDEa59458dF745Ed2BA6F1343458287f7608f9c4f',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x80',
        chainName: 'HECO Chain',
        nativeCurrency: {
          name: 'HT',
          symbol: 'HT',
          decimals: 18,
        },
        rpcUrls: ['https://http-mainnet.hecochain.com'],
        blockExplorerUrls: ['https://hecoinfo.com//'],
      },
    ],
    blockchain_id: '15',
    explorer: 'https://hecoinfo.com/tx/',
    addressExplorer: 'https://hecoinfo.com/address/{account}',
    isEVM: true,
    CHAIN_ID: 128,
    stableTokens: [
      '0x0298c2b32eae4da002a15f36fdf7615bea3da047',
      '0xa71edc38d189767582c38a3145b5873052c3e47a',
    ],
  },
  MATIC: {
    WRAP_TOKENS: ['0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'],
    BAL_CONTRACT: '0xfe8657C2522DfE79d6eC4AB2534CC9468B7a9F6B',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x89',
        chainName: 'Polygon',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
    ],
    blockchain_id: '18',
    explorer: 'https://polygonscan.com/tx/',
    addressExplorer: 'https://polygonscan.com/address/{account}',
    isEVM: true,
    CHAIN_ID: 137,
    stableTokens: [
      '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    ],
  },
  HSC: {
    WRAP_TOKENS: ['0x3EFF9D389D13D6352bfB498BCF616EF9b1BEaC87'],
    BAL_CONTRACT: '0x16A715DcB38600d3E0C308b94D768dCF4faCb399',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x46',
        chainName: 'Hoo Smart Chain',
        nativeCurrency: {
          name: 'HOO',
          symbol: 'HOO',
          decimals: 18,
        },
        rpcUrls: ['https://http-mainnet.hoosmartchain.com'],
        blockExplorerUrls: ['https://hooscan.com/'],
      },
    ],
    blockchain_id: '19',
    explorer: 'https://hooscan.com/tx/',
    addressExplorer: 'https://hooscan.com/address/{account}',
    isEVM: true,
    CHAIN_ID: 70,
    stableTokens: [
      '0x92a0bd4584c147d1b0e8f9185db0bda10b05ed7e',
      '0xd16babe52980554520f6da505df4d1b124c815a7',
    ],
  },
  FTM: {
    WRAP_TOKENS: ['0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'],
    BAL_CONTRACT: '0xAE2941f648ad7abe4abf5C557c635EBd82e6A194',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xfa',
        chainName: 'Fantom',
        nativeCurrency: {
          name: 'FTM',
          symbol: 'FTM',
          decimals: 18,
        },
        rpcUrls: ['https://rpcapi.fantom.network'],
        blockExplorerUrls: ['https://ftmscan.com/'],
      },
    ],
    blockchain_id: '25',
    explorer: 'https://ftmscan.com/tx/',
    addressExplorer: 'https://ftmscan.com/address/{account}',
    isEVM: true,
    CHAIN_ID: 250,
    stableTokens: [
      '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    ],
  },
  OEC: {
    WRAP_TOKENS: ['0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15'],
    BAL_CONTRACT: '0x2d64a09f36b61662fdab6faee3a49d6681c5816c',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x42',
        chainName: 'OKChain',
        nativeCurrency: {
          name: 'OKT',
          symbol: 'OKT',
          decimals: 18,
        },
        rpcUrls: ['https://exchainrpc.okex.org'],
        blockExplorerUrls: ['https://www.oklink.com/okexchain/'],
      },
    ],
    blockchain_id: '20',
    explorer: 'https://www.oklink.com/okc/tx/',
    addressExplorer: 'https://www.oklink.com/okexchain/address/{account}',
    isEVM: true,
    CHAIN_ID: 66,
    stableTokens: [
      '0x382bb369d343125bfb2117af9c149795c6c65c50',
      '0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
      '0x332730a4f6e03d9c55829435f10360e13cfa41ff',
    ],
  },
  ARB: {
    WRAP_TOKENS: ['0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'],
    BAL_CONTRACT: '0xcF10501Ed85b4b66282310cAa0f3dabEf15D8e51',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa4b1',
        chainName: 'Arbitrum',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://arbiscan.io/'],
      },
    ],
    blockchain_id: '24',
    explorer: 'https://arbiscan.io/tx/',
    addressExplorer: 'https://arbiscan.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 42161,
    stableTokens: [
      '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    ],
  },
  AVAX: {
    WRAP_TOKENS: ['0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'],
    BAL_CONTRACT: '0xe7281717FbA2D77e1D85CF3b8b6E069a8a69B213',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa86a',
        chainName: 'Avalanche',
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
      },
    ],
    blockchain_id: '23',
    explorer: 'https://snowtrace.io/tx/',
    addressExplorer: 'https://cchain.explorer.avax.network/address/{account}',
    isEVM: true,
    CHAIN_ID: 43114,
    stableTokens: [
      '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
      '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
      '0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB',
    ],
  },
  KCC: {
    WRAP_TOKENS: ['0x4446fc4eb47f2f6586f9faab68b3498f86c07521'],
    BAL_CONTRACT: '0x0F3c008865B730BE411E6619c46bE425b5A3b9CD',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x141',
        chainName: 'KCC Mainnet',
        nativeCurrency: {
          name: 'KCS',
          symbol: 'KCS',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-mainnet.kcc.network'],
        blockExplorerUrls: ['https://explorer.kcc.io/'],
      },
    ],
    blockchain_id: '35',
    explorer: 'https://explorer.kcc.io/en/tx/',
    addressExplorer: 'https://explorer.kcc.io/en/address/{account}',
    isEVM: true,
    CHAIN_ID: 321,
    stableTokens: [
      '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48',
      '0x980a5afef3d17ad98635f6c5aebcbaeded3c3430',
    ],
  },
  CFX: {
    WRAP_TOKENS: ['0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b'],
    BAL_CONTRACT: '0x16A715DcB38600d3E0C308b94D768dCF4faCb399',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x406',
        chainName: 'Conflux eSpace',
        nativeCurrency: {
          name: 'CFX',
          symbol: 'CFX',
          decimals: 18,
        },
        rpcUrls: ['https://evm.confluxrpc.com'],
        blockExplorerUrls: ['https://evm.confluxscan.net/'],
      },
    ],
    blockchain_id: '36',
    explorer: 'https://evm.confluxscan.net/tx/',
    addressExplorer: 'https://evm.confluxscan.net/address/{account}',
    isEVM: true,
    CHAIN_ID: 1030,
    stableTokens: [
      '0xfe97e85d13abd9c1c33384e796f10b73905637ce',
      '0x6963efed0ab40f6c3d7bda44a05dcf1437c44372',
    ],
  },
  GLMR: {
    WRAP_TOKENS: ['0xacc15dc74880c9944775448304b263d191c6077f'],
    BAL_CONTRACT: '0x58F51a75759E21B257AB1d65C9B07e378CD66454',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x504',
        chainName: 'Moonbeam',
        nativeCurrency: {
          name: 'GLMR',
          symbol: 'GLMR',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.api.moonbeam.network'],
        blockExplorerUrls: ['https://moonscan.io/'],
      },
    ],
    blockchain_id: '31',
    explorer: 'https://moonscan.io/tx/',
    addressExplorer: 'https://moonscan.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 1284,
    stableTokens: [
      '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
      '0x931715FEE2d06333043d11F658C8CE934aC61D0c',
      '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
      '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    ],
  },
  KLAY: {
    WRAP_TOKENS: [],
    BAL_CONTRACT: '0x5e3f7e652271142531f14e8bc89f77f112b820a7',
    AGGR_INFO: {
      contract: '0x5e6B23f33E1a6653B1DD7aDfE89996028b3aFee6',
      approveProxy: '0x4517F5065C2f1c5508DD67dd1db95227cA87Abe8',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x2019',
        chainName: 'Klaytn',
        nativeCurrency: {
          name: 'KLAY',
          symbol: 'KLAY',
          decimals: 18,
        },
        rpcUrls: ['https://kaikas.cypress.klaytn.net:8651/'],
        blockExplorerUrls: ['https://scope.klaytn.com/'],
      },
    ],
    blockchain_id: '22',
    explorer: 'https://scope.klaytn.com/tx/',
    addressExplorer: 'https://scope.klaytn.com/account/{account}',
    isEVM: true,
    CHAIN_ID: 8217,
    stableTokens: ['0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167'],
  },
  OP: {
    WRAP_TOKENS: ['0x4200000000000000000000000000000000000006'],
    BAL_CONTRACT: '0x9ff92249ae03fee969ba998655dc807854b29a7b',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0xa',
        chainName: 'Optimism',
        nativeCurrency: {
          name: 'OETH',
          symbol: 'OETH',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.optimism.io/'],
        blockExplorerUrls: ['https://optimistic.etherscan.io/'],
      },
    ],
    blockchain_id: '26',
    explorer: 'https://optimistic.etherscan.io/tx/',
    addressExplorer: 'https://optimistic.etherscan.io/address/{account}',
    isEVM: true,
    CHAIN_ID: 10,
    stableTokens: [
      '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    ],
  },
  ETC: {
    WRAP_TOKENS: [
      '0x82A618305706B14e7bcf2592D4B9324A366b6dAd',
      '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
    ],
    BAL_CONTRACT: '0x6Daff8De7Da51eEbF8F687b1A5cE91F7FbE20D9d',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      // approveProxy: '0xeb2202B5646c237e93057bE912a3Cc4f4FDa290e',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x3d',
        chainName: 'Ethereum Classic',
        nativeCurrency: {
          name: 'ETC',
          symbol: 'ETC',
          decimals: 18,
        },
        rpcUrls: ['https://www.ethercluster.com/etc'],
        blockExplorerUrls: ['https://blockscout.com/etc/mainnet/'],
      },
    ],
    blockchain_id: '41',
    explorer: 'https://blockscout.com/etc/mainnet/tx/',
    addressExplorer: 'https://blockscout.com/etc/mainnet/address/{account}',
    isEVM: true,
    CHAIN_ID: 61,
    stableTokens: [
      '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
      '0xc9baa8cfdde8e328787e29b4b078abf2dadc2055',
    ],
  },
  ETHW: {
    WRAP_TOKENS: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    BAL_CONTRACT: '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x2711',
        chainName: 'ETHPoW Mainnet',
        nativeCurrency: {
          name: 'ETHW',
          symbol: 'ETHW',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.ethereumpow.org'],
        blockExplorerUrls: ['https://www.oklink.com/en/ethw/'],
      },
    ],
    blockchain_id: null,
    explorer: 'https://www.oklink.com/en/ethw/tx/',
    isEVM: true,
    CHAIN_ID: 10001,
    stableTokens: [
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    ],
  },
  ETHF: {
    WRAP_TOKENS: ['0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'],
    BAL_CONTRACT: '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39',
    AGGR_INFO: {
      contract: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
      approveProxy: '0xb45A2DDA996C32E93B8c47098E90Ed0E7ab18E39',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x7d44c',
        chainName: 'EthereumFair ETHF',
        nativeCurrency: {
          name: 'ETHF',
          symbol: 'ETHF',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.etherfair.org'],
        blockExplorerUrls: ['https://explorer.etherfair.org'],
      },
    ],
    blockchain_id: null,
    explorer: 'https://explorer.etherfair.org/tx/',
    isEVM: true,
    CHAIN_ID: 513100,
    stableTokens: [
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    ],
  },
  TRX: {
    WRAP_TOKENS: ['TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR'],
    BAL_CONTRACT: 'TWSaaayu3N1z5GKeWYyTkUG1p9tw3tdTHw',
    AGGR_INFO: {
      contract: 'TKqQ7zuL7yKp1NWuhGpoXTDiE3j2qiAbRE',
      // approveProxy: 'TTLaNDdcL5rMfxMS2VL1UCa44ebRCNbqew',
      approveProxy: 'TKqQ7zuL7yKp1NWuhGpoXTDiE3j2qiAbRE',
    },
    CHAIN_PARAMS: [
      {
        chainId: '0x1750c',
        chainName: 'TRON Mainnet',
      },
    ],
    blockchain_id: '10',
    explorer: 'https://tronscan.io/#/transaction/',
    addressExplorer: 'https://tronscan.io/#/address/{account}',
    isEVM: false,
    CHAIN_ID: 95500,
  },

  // 'Rinkeby': {
  //     WRAP_TOKENS: ['0xc778417e063141139fce010982780140aa0cd5ab'],
  //     BAL_CONTRACT: "0x32cD71544DFA795c18f0BB35e12E6a0Cc371763F",
  //     AGGR_INFO: {
  //         contract: '0x5f23149ADb36c57774350133b8D614c0DFa8536B',
  //         approveProxy: '0x5f23149ADb36c57774350133b8D614c0DFa8536B',

  //     },
  //     CHAIN_PARAMS: [{
  //         chainId: "0x4",
  //         chainName: "Rinkeby Testnet",
  //         nativeCurrency: {
  //             name: "ETH",
  //             symbol: "ETH",
  //             decimals: 18
  //         },
  //         rpcUrls: ["https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  //         blockExplorerUrls: ['http://rinkeby.etherscan.io/']
  //     }],
  //     explorer: "https://rinkeby.etherscan.io/tx/",
  //     isEVM: true,
  //     CHAIN_ID: 4,
  // },
  // 'XDAI': {
  //     WRAP_TOKENS: ['',
  // ]    BAL_CONTRACT: "0x91FB50cCfd6c003fD237e8A11B2b2d7244664C57",
  //     AGGR_INFO: {
  //         contract: '0x4184d9A175D13e568F3466Ea93C02B6F8eb9F8c1',
  //         approveProxy: '0x4184d9A175D13e568F3466Ea93C02B6F8eb9F8c1',

  //     },
  //     CHAIN_PARAMS: [{
  //         chainId: "0x64",
  //         chainName: "Gnosis Chain",
  //         nativeCurrency: {
  //             name: "xDAI",
  //             symbol: "xDAI",
  //             decimals: 18
  //         },
  //         rpcUrls: ["https://dai.poa.network"],
  //         blockExplorerUrls: ['https://blockscout.com/xdai/mainnet/']
  //     }],
  //     blockchain_id: '1',
  //     explorer: 'https://blockscout.com/xdai/mainnet/tx/',
  //     isEVM: true,
  //     CHAIN_ID: 100
  // },
  SOL: {
    isEVM: false,
    blockchain_id: '27',
    explorer: 'https://explorer.solana.com/tx/',
    addressExplorer: 'https://solscan.io/account/{account}',
  },
  EOS: {
    blockchain_id: '4',
    addressExplorer: 'http://bloks.io/account/{account}',
  },
  BTC: {
    blockchain_id: '11',
    addressExplorer: 'https://www.blockchain.com/btc/address/{account}',
  },
  DOT: {
    blockchain_id: '13',
    addressExplorer: 'https://polkadot.subscan.io/account/{account}',
  },
  KSM: {
    blockchain_id: '14',
    addressExplorer: 'https://kusama.subscan.io/account/{account}',
  },
  IOST: {
    blockchain_id: '7',
    addressExplorer: 'https://explorer.iost.io/account/{account}',
  },
  APTOS: {
    blockchain_id: '43',
    explorer: 'https://explorer.aptoslabs.com/txn/',
    addressExplorer: 'https://explorer.aptoslabs.com/account/{account}',
  },
};
