<template>
  <div id="app" :class="{ dark: isDark }">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: { ...mapGetters(['isDark']) },
};
</script>
<style lang="scss">
#qpp {
  background: #fff;
}
@media screen and (max-width: 767px) {
  #app {
    background: #f6f6f6;
    &.dark {
      background: #0d0e0f;
    }
  }
}
</style>
