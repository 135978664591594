<template>
  <div>
    <div class="language-wrapper" :class="{ darkmode: mode, lightmode: !mode }">
      <div
        class="selectedLanguage"
        @click="togglePanel(!showPanel)"
        :class="{ hint: showPanel }"
      >
        {{ currentLanguageTitle }}
        <div class="language-panel" v-if="showPanel">
          <div class="language-back">
            <img :src="arrowImg" />
          </div>
          <div
            class="languagein"
            v-for="language in languages"
            :key="language.lang"
            @click.stop="changeLanguage(language)"
          >
            {{ language.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPanel: false,
      currentLanguage: this.$i18n.locale,
      // buttonText: this.$i18n.locale,
      languages: [
        { title: "简体中文", lang: "zh" },
        { title: "English", lang: "en" },
      ],
      languageImg: require(`../assets/language.png`),
      arrowImg: require(`../assets/arrow-w.png`),
    };
  },
  computed: {
    currentLanguageTitle() {
      const language = this.languages.find((lang) => lang.lang === this.currentLanguage);
      return language ? language.title : "";
    },
  },
  methods: {
    togglePanel(bool) {
      this.showPanel = bool;
    },
    changeLanguage(language) {
      // this.$emit('changeLanguage',lang)
      this.currentLanguage = language.lang;
      // this.buttonText = language.title;
      this.showPanel = false;
      this.$i18n.locale = language.lang;
    },
  },
};
</script>

<style lang="scss" scoped>
.lightmode {
  .selectedLanguage {
    color: #101010;
    font-size: 0.26rem;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
  }
  .hint {
    color: #2980fe;
  }
  .language-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    overflow: hidden;
    .language-img {
      margin-right: 10px;
      width: 0.24rem;
      height: 0.24rem;
    }
  }
  .language-panel {
    position: absolute;
    width: calc(100% - 50px);
    height: 100%;
    overflow-y: auto;
    top: 0px;
    left: 25px;
    background: #fff;
    transition: all 0.3s linear;
    z-index: 10;
    border-radius: 12px;
    padding: 0 24px;
    .language-back {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 13;
      padding: 16px 0;
      background: #fff;
      width: 100%;
      img {
        width: 12px;
        height: auto;
        rotate: -180deg;
      }
      border-bottom: 1px solid #eee;
    }
    .languagein {
      position: relative;
      height: 50px;
      line-height: 50px;
      color: #101010;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        color: #2980fe;
      }
      font-size: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      .language-img {
        margin-right: 10px;
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
}
.darkmode {
  .selectedLanguage {
    color: #fff;
    font-size: 0.26rem;
    font-weight: 400;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hint {
    color: #2980fe;
  }
  .language-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    overflow: hidden;
    .language-img {
      margin-right: 10px;
      width: 0.24rem;
      height: 0.24rem;
    }
  }
  .language-panel {
    position: absolute;
    width: calc(100% - 50px);
    height: 100%;
    overflow-y: auto;
    top: 0px;
    left: 25px;
    background: #212326;
    transition: all 0.3s linear;
    z-index: 10;
    border-radius: 12px;
    padding: 0 24px;
    .language-back {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 13;
      padding: 16px 0;
      width: 100%;
      background: #212326;
      img {
        width: 12px;
        height: auto;
        rotate: -180deg;
      }
      border-bottom: 1px solid #444;
    }
    .languagein {
      position: relative;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        color: #2980fe;
      }
      font-size: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      .language-img {
        margin-right: 10px;
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
}
</style>
