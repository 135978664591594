<template>
  <div class="user-info-item d-flex ai-center jc-between">
    <div class="d-flex ai-center">
      <div class="order text" v-if="!lp_holders">{{ index + 1 }}.</div>
      <div class="mark mark-contract" v-if="item.is_contract">
        <img :src="contractImg" @click="sendToastTitle(0)" />
        <div class="tip-text">
          {{ $t('smartContract') }}
          <div class="triangle"></div>
        </div>
      </div>
      <div class="mark mark-contract mark-locked" v-if="item.is_locked">
        <img :src="lockedImg" @click="sendToastTitle(1)" />
        <div class="tip-text">
          {{ $t('locked') }}
          <div class="triangle"></div>
        </div>
      </div>
      <span
        class="text address"
        @click="onBlockchainBrowser(item.address, chain_id, blockchain_id)"
      >
        {{ String(item.address).slice(0, 8) }}...{{
          String(item.address).slice(-5)
        }}
      </span>
    </div>
    <!-- <span class="text balance flex-1">
      {{ Number(item.balance).toLocaleString() }} ({{
        (Number(item.percent) * 100).toFixed(2)
      }}%)
    </span> -->
    <div class="balance-wrap flex-1">
      <span class="balance">{{ Number(item.balance).toLocaleString() }}</span>
      <span class="balance percent">({{ (Number(item.percent) * 100).toFixed(2) }})</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GlobalMixins } from '../mixins';
export default {
  props: {
    lp_holders: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    chain_id: {
      type: Number,
    },
    blockchain_id: {
      type: Number,
    },
    index: {
      type: Number,
    },
  },
  mixins: [GlobalMixins],
  computed: {
    ...mapGetters(['isDark']),
    contractImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return require(`../assets/is_contract${str}.png`);
    },
    lockedImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return require(`../assets/locked${str}.png`);
    },
  },
  methods: {
    sendToastTitle(type) {
      let obj = {};
      if (type === 0) {
        obj = { title: this.$t('smartContract'), short: true };
      } else {
        obj = { title: this.$t('locked'), short: true };
      }
      this.$emit('getToastTitle', obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info-item {
  margin-top: 0.12rem;
  .order {
    min-width: 0.16rem;
    margin-right: 0.16rem;
    font-size: 0.14rem;
    font-family: PingFangSC, PingFangSC-Regular;
    color: var(--gray-14);
  }
  span {
    font-size: 0.14rem;
  }
  .mark {
    display: inherit;
    position: relative;
    // order: 3;
    // order: 0;
    img {
      margin-right: 0.06rem;
      // margin-left: 0.08rem;
      width: 0.16rem;
      // height: 0.16rem;
      cursor: pointer;
    }
    .tip-text {
      left: 50%;
      top: -0.42rem;
      display: none;
      .triangle {
        border-top: 0.1rem solid var(--tip-bg);
      }
    }
  }
  .mark-contract:hover {
    .tip-text {
      display: block;
      background: var(--tip-bg);
      font-size: 0.12rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      padding: 0.06rem 0.15rem;
    }
  }
  .mark-locked:hover {
    .tip-text {
      display: block;
    }
  }
  .address {
    font-family: PingFang SC, PingFang SC-Regular;
    color: var(--gray-14);
    cursor: pointer;
  }
  .address:hover {
    text-decoration: underline;
  }
  .balance-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .balance {
      // margin-left: 0.5rem;
      margin-left: 0.25rem;
      text-align: right;
      color: var(--gray-1);
      word-break: break-all;
      font-family: PingFang SC, PingFang SC-Regular;
    }
    .percent {
      margin-left: 0.2rem;
      white-space: nowrap;
    }
  }
}
@media screen and (max-width: 767px) {
  .user-info-item {
    margin-top: 0.32rem;
    .order {
      min-width: 0.25rem;
      font-size: 0.24rem;
    }
    span {
      font-size: 0.24rem;
    }
    .mark {
      display: inherit;
      position: relative;
      order: 0;
      img {
        margin-right: 0.08rem;
        margin-left: 0;
        width: 0.24rem;
        cursor: pointer;
      }
      .tip-text {
        left: 45%;
        display: none;
      }
    }
    .mark:hover {
      .tip-text {
        display: none;
      }
    }
    .mark-contract:hover {
      .tip-text {
        display: none;
      }
    }
    .mark-locked:hover {
      .tip-text {
        display: none;
      }
    }
    .address {
      font-family: PingFang SC, PingFang SC-Regular;
      // color: var(--gray-12);
    }
    .address:hover {
      text-decoration: none;
    }
    .balance {
      margin-left: 0.5rem;
      text-align: right;
    }
  }
}
@media screen and (min-width: 767px) {
  .user-info-item {
    .mark {
      img {
        pointer-events: none;
      }
    }
  }
}
</style>
