<template>
  <div class="liquidity-pool">
    <div class="title text">{{ $t('liquidityPool') }}</div>
    <div class="dex-list">
      <div class="dex-item dex-header">
        <div class="d-flex ai-center jc-center">
          <div class="text AMM">{{ $t('amm') }}</div>
          <div class="text Pair">{{ $t('pair') }}</div>
          <div class="text Amount">{{ $t('amount') }}</div>
          <div class="text liquidity">{{ $t('liquidity') }}</div>
        </div>
      </div>
      <div class="dex-item" v-for="(item, index) in ammList" :key="index">
        <div class="d-flex ai-center">
          <div class="AMM">
            <!-- <img class="mark" src="../assets/locked.png" /> -->
            <img class="icon" :src="item.logo" />
            <span class="text">{{ item.name }}</span>
          </div>
          <div class="Pair">
            <span class="text">{{ item.pair.symbol0 }} </span>
            <div class="right">
              <span class="slash">/</span>
              <span class="text">{{ item.pair.symbol1 }}</span>
            </div>
          </div>
          <div class="Pair Amount">
            <span class="text">{{ item.pair.reserve0 }} </span>
            <div class="right">
              <span class="slash">/</span>
              <span class="text">{{ item.pair.reserve1 }}</span>
            </div>
          </div>
          <div class="liquidity">$ {{ item.value }}</div>
        </div>
        <div class="line" v-if="index !== ammList.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ammList: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.liquidity-pool {
  margin-top: 0.2rem;
  .title {
    padding: 0;
    text-align: left;
    color: var(--gray-10);
    line-height: normal;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
  }
  .dex-list {
    margin-top: 0.1rem;
    // border: 0.01rem solid var(--input-border);
    border-radius: 0.08rem;
    // padding: 0.1rem 0.1rem 0.2rem;
    padding: 0.17rem 0;
    background: var(--bg-12);
    .dex-item {
      padding: 0 0.3rem;
      margin-top: 0.13rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .line {
        margin-top: 0.1rem;
      }
      .AMM {
        display: flex;
        align-items: center;
        // flex-direction: column;
        flex: 2;
        .mark {
          width: 0.12rem;
        }
        .icon {
          // margin: 0 0.06rem;
          margin-right: 0.06rem;
          width: 0.2rem;
        }
        span {
          font-size: 0.12rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: var(--gray-14);
        }
      }
      .Pair {
        display: flex;
        flex: 1;
        span {
          font-weight: 400;
          text-align: left;
          font-size: 0.12rem;
          font-family: PingFangSC, PingFangSC-Regular;
          color: var(--gray-10);
        }
        .right {
          display: flex;
          margin-left: 0.04rem;
          span {
            color: var(--gray-14);
          }
          .slash {
            margin-right: 0.04rem;
          }
        }
      }
      .Amount {
        flex: 1;
      }
      .liquidity {
        flex: 1;
        font-size: 0.12rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: var(--gray-10);
        text-align: right;
      }
    }
    .dex-header {
      margin-top: 0;
      padding: 0 0.3rem 0.11rem;
      // margin-bottom: 0.11rem;
      // width: 4.28rem;
      // height: 0.32rem;
      // background: var(--percent-bg);
      border-radius: 0.08rem;
      // padding: 0 0.2rem;
      .text {
        font-size: 0.12rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: var(--gray-10);
      }
      .liquidity {
        text-align: right;
      }
      .AMM {
        // transform: translateX(20px);
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .liquidity-pool {
    margin-top: 0.7rem;
    .title {
      font-size: 0.24rem;
    }
    .dex-list {
      margin-top: 0.2rem;
      padding: 0.2rem 0;
      .dex-item {
        .AMM {
          flex: 1;
          .icon {
            width: 0.44rem;
          }
          span {
            display: none;
          }
        }
        .Pair {
          flex-direction: column;
          span {
            font-size: 0.22rem;
          }
          .right {
            margin-left: 0;
          }
        }
        .liquidity {
          font-size: 0.22rem;
        }
      }
      .dex-header {
        .text {
          font-size: 0.22rem;
        }
      }
    }
  }
}
</style>
