export default {
  submit: 'Submit',
  loading: 'Loading...',
  tokenInfoTitle: 'Token Profile',
  tokenDetectionTitle: 'Security Detection',
  baseInfo: 'Profile Summary',
  projectSymbol: 'Token Symbol',
  projectName: 'Project',
  contractAddress: 'Contract',
  contractOwner: 'Contract Owner',
  website: 'Official Site',
  socialInfo: 'Social Profiles',
  more: 'more',
  releaseInfo: 'Token Overview',
  releaseTotal: 'Total Supply',
  releaseTime: 'Issue Time',
  securityInfo: 'Security Info',
  moreSecurityInfo: 'View more security details',
  detection: 'Check',
  network: 'choose network',
  detectionDesc:
    "The security detection is for reference only base on the on-chain data analysis. It doesn't mean that the data is 100% correct. Please always do your own researches and do your own risks. TokenPocket will commit to provide more useful data to users.",
  securityOverview: 'Security Overview',
  risky: 'Risky Items',
  attentionTip: 'Attention Items',
  contractCreator: 'Creator',
  circulation: 'Circulation',
  tradingSecurityReport: 'Trading Security Report',
  buy_tax: 'Buy Tax',
  sell_tax: 'Sell Tax',
  unknown_tax: 'unknown',
  contractSecurityReport: 'Contract Security Report',
  trade_0_text1: 'This seems not like a honeypot',
  trade_0_text2: "Trading tax can't be modified",
  trade_0_text3: "Trading can't be suspended",
  trade_0_text4: 'No blacklist feature',
  trade_0_text5: 'No whitelist feature',
  trade_0_text6: 'No anti whale feature',
  trade_0_text7: 'Can buy',
  trade_0_text8: 'Can sell all',
  trade_0_text9: 'Has not trading cool down',
  trade_0_text10: 'Cannot be able to modify slippage by personal',
  trade_0_text11: 'No functions to modify maximum token transfer or trade',

  trade_1_text1: 'This is a honeypot!',
  trade_1_text2: 'Trading tax can be modified',
  trade_1_text3: 'Trading can suspended',
  trade_1_text4: 'Has blacklist feature',
  trade_1_text5: 'Has whitelist feature',
  trade_1_text6: 'Has anti whale feature',
  trade_1_text7: 'Cannot buy',
  trade_1_text8: 'Cannot sell all',
  trade_1_text9: 'Has trading cool down',
  trade_1_text10: 'Be able to modify slippage by personal',
  trade_1_text11: 'Have function to modify maximum token transfer or trade',

  trade_2_text1: 'Owner transferred, cannot pause transfer',
  trade_2_text2: 'Owner transferred, cannot set trading cooldown',
  trade_2_text3: 'Owner transferred, cannot set slippage',
  trade_2_text4: 'Owner transferred, cannot update maximal token position', 

  trade_token_status_text1: "It's marked as phishing or scaming token",

  airdropScam: 'It is an airdrop scam',
  fakeToken: 'It is a fake token',

  contract_0_text1: 'Contract source code verified',
  contract_0_text2: 'No proxy contracts',
  contract_0_text3: 'No mint features',
  contract_0_text4: 'Owner cannot change balance',
  contract_0_text5: 'No hidden owners',
  contract_0_text6: 'Cannot take back ownership',
  contract_0_text7: 'Cannot be able to self destructed',
  contract_0_text8: 'There is no external call on this contract',

  contract_1_text1: 'Contract source code not verified',
  contract_1_text2: 'It has a proxy contract',
  contract_1_text3: 'It has a mint feature',
  contract_1_text4: 'Owner can change balance',
  contract_1_text5: 'Has a hidden owner',
  contract_1_text6: 'Can take back ownership',
  contract_1_text7: 'Be able to self destructed, assets can be empty',
  contract_1_text8: 'There is an external call on this contract',

  contract_2_text1: 'Owner transferred, cannot mint more token',
  contract_2_text2: 'Owner transferred, cannot modify balance',

  copySuccess:
    "Copied. Attentions, the address you just copied is a contract address, please don't transfer any assets to this address, or you will lose your assets!",
  report: 'report',
  placeholder: 'Input token contract address',

  errorContract:
    'Query failed, please confirm if the contract address is correct',
  errorData: 'Cannot find the data, please try again later',
  understood: 'Understood',
  copySuccess2: 'Successfully copied',
  copySuccess3: 'Link copied successfully',

  is_honeypot:
    'Honeypot Token: a worthless token cannot be resold when it is bought.',
  locked: 'Locked',
  smartContract: 'Smart Contract',

  liquidityPool: 'Liquidity Pool',
  poolInfo: 'Pool Info',
  lockedPercent: 'Percentage of LP locked',
  amm: 'AMM',
  pair: 'Pair',
  amount: 'Amount',
  liquidity: 'Liquidity',

  share_report: 'Share Report',
  check_report: 'Check Report',
  contract_ownership: 'Owner has been discarded',
  chain_not_found:'Chain not found～',
  settings:'Settings',
  languages:'Languages',
  search_placeholder:'Enter the network name',
  darkmode:'Dark Mode',
};
